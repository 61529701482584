<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="far fa-calendar-alt"
      icon-small
      color="primary"
      :title="$t('pageTitles.Events/EventsList')"
    >
      <template #subtitle>
        <div class="text-right text-h3">
          <app-btn
            color="success"
            class="px-2 ml-1"
            elevation="0"
            min-width="0"
            small
            to="/events/events/new"
          >
            <v-icon
              small
              v-text="'fas fa-plus'"
            />
          </app-btn>
        </div>
      </template>

      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>{{ $t('forms.label') }}</th>
            <th>{{ $t('forms.category') }}</th>
            <th>{{ $t('forms.on_homepage') }}</th>
            <th>{{ $t('forms.enabled') }}</th>
            <th>{{ $t('forms.dates') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!events || !events.length">
          <tr>
            <td colspan="4">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="event in events"
            :key="`events-list-${event.id}`"
          >
            <td>{{ event.label }}</td>
            <td>{{ event.event_category.label }}</td>
            <td>
              <span
                v-if="event.is_highlighted"
                class="success--text"
              >
                <i class="fas fa-check mr-3"></i> {{ $t('yes') }}
              </span>
              <span
                v-else
                class="error--text"
              >
                <i class="fas fa-times mr-3"></i> {{ $t('no') }}
              </span>
            </td>
            <td>
              <span
                v-if="event.is_enabled"
                class="success--text"
              >
                <i class="fas fa-check mr-3"></i> {{ $t('yes') }}
              </span>
              <span
                v-else
                class="error--text"
              >
                <i class="fas fa-times mr-3"></i> {{ $t('no') }}
              </span>
            </td>
            <td>
              <span v-if="event.date_type === 'range'">
                {{ $t('forms.from') }} {{ formatDate(event.start_at) }} {{ $t('forms.until') }} {{ formatDate(event.end_at) }}
              </span>
              <span v-else-if="event.date_type === 'unique'">
                Date 1 : {{ formatDate(event.start_at) }}, Date 2 : {{ formatDate(event.end_at) }}
              </span>
            </td>
            <td class="text-right table-actions">
              <v-btn
                color="success"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                :to="`/events/events/${event.id}`"
              >
                <v-icon
                  small
                  v-text="'far fa-edit'"
                />
              </v-btn>
              <app-btn
                color="warning"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateEventDuplicate(event)"
              >
                <v-icon
                  small
                  v-text="'fas fa-copy'"
                />
              </app-btn>
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateEventDelete(event)"
              >
                <v-icon
                  small
                  v-text="'fas fa-trash'"
                />
              </app-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-if="pageRange && pageRange.length"
        class="w-100 d-flex justify-center"
      >
        <div
          v-for="page in pageRange"
          :key="`pagination-${page}`"
          class="mx-2"
          :class="{'font-weight-bold': currentPage === page}"
          style="cursor: pointer;"
          @click="currentPage = page"
        >
          {{ page }}
        </div>
      </div>
    </material-card>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteEvent"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDuplicationDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('duplicate_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDuplicationDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="duplicateEvent"
          >
            {{ $t('duplicate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'EventsList',

  mixins: [serverError],

  data: () => ({
    events: [],
    showDeletionDialog: false,
    showDuplicationDialog: false,
    eventToDelete: null,
    eventToDuplicate: null,
    perPage: 50,
    currentPage: 1,
  }),

  computed: {
    ...mapGetters({
      allEvents: 'event/getEvents',
      meta: 'event/getEventsMeta',
      processing: 'global/getProcessing',
    }),
    pageRange() {
      if (!this.meta?.last_page || this.meta.last_page <= 1) {
        return [];
      }
      const range = [];
      for (let i = 0; i < this.meta.last_page; i++) {
        range.push(i + 1);
      }
      return range;
    },
  },

  watch: {
    currentPage() {
      this.refreshData();
    },
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.events = [];
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        sort: '-start_at',
      };
      this.$store.dispatch('event/fetchEvents', { params })
        .then(() => {
          this.events = [...this.allEvents];
        });
    },
    initiateEventDelete(event) {
      this.eventToDelete = event.id;
      this.showDeletionDialog = true;
    },
    closeDeletionDialog() {
      this.eventToDelete = null;
      this.showDeletionDialog = false;
    },
    deleteEvent() {
      this.$store.dispatch('event/deleteEvent', this.eventToDelete)
        .then(() => {
          this.$toasted.success(this.$t('item_deleted'));
          this.refreshData();
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDeletionDialog());
    },
    initiateEventDuplicate(event) {
      this.eventToDuplicate = event.id;
      this.showDuplicationDialog = true;
    },
    closeDuplicationDialog() {
      this.eventToDuplicate = null;
      this.showDuplicationDialog = false;
    },
    duplicateEvent() {
      this.$store.dispatch('event/duplicateEvent', this.eventToDuplicate)
        .then(response => {
          this.$toasted.success(this.$t('item_duplicated'));
          this.$router.push(`/events/events/${response.data.data.id}`);
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDuplicationDialog());
    },
    formatDate(date) {
      if (!date) {
        return 'Non indiqué';
      }
      return this.$moment(date).format('ddd D MMM YYYY');
    },
  },
}
</script>
